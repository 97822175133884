import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const qualityFirstUIDevelopmentQuery = graphql`
  query QualityFirstUIDevelopmentQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  qualityFirstUIDevelopmentQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`In the same spirit as `}<a parentName="p" {...{
        "href": "https://en.ryte.com/wiki/Mobile_First"
      }}>{`mobile-first`}</a>{` and `}<a parentName="p" {...{
        "href": "http://offlinefirst.org/"
      }}>{`offline-first`}</a>{`, I want to write about quality-first.`}</p>
    <blockquote>
      <p parentName="blockquote">{`It merely means: start with quality in mind and don’t make it an afterthought.`}</p>
    </blockquote>
    <p>{`If we say we are professional developers, the quality of the systems we produce is our responsibility.`}</p>
    <p>{`Does this apply to you? Ask yourself these two questions:`}</p>
    <ol>
      <li parentName="ol">{`Does the majority of the work you do revolve around software development? Not `}<em>{`coding`}</em>{`, but `}<em>{`development`}</em></li>
      <li parentName="ol">{`Are you paid to do it?`}</li>
    </ol>
    <p>{`If you answered `}{`"`}{`yes`}{`"`}{` to both these questions, congratulations, you are a professional! 🎉`}</p>
    <blockquote>
      <p parentName="blockquote">{`You can let the imposter syndrome go, you made it!`}</p>
    </blockquote>
    <p>{`No one knows everything. Not even close. We're all still on the journey to master this craft. Everyone started with zero knowledge, zero experience.`}</p>
    <hr></hr>
    <p>{`Now that we've established that quality is our responsibility let's talk about it.`}</p>
    <p>{`We're going to look at quality from two perspectives: UX & DX (user experience and developer experience)`}</p>
    <p>{`Instead of getting hung up about the correct definitions here, in light of this post, they mean quality from the perspective of the end-user and the developer.`}</p>
    <p>{`I would argue that good DX goes a long way in helping to produce good UX. If you don't agree, let me know on `}<a parentName="p" {...{
        "href": "https://twitter.com/jpunk11"
      }}>{`Twitter`}</a>{`.`}</p>
    <h2>{`UX`}</h2>
    <p>{`We all agree UX is essential. If users have a terrible experience, they'll leave, and without users, what's the point in what we build?`}</p>
    <p>{`To produce a good user experience, we need to understand our users and how they use our products.`}</p>
    <p>{`Some questions we need to ask about our users:`}</p>
    <ul>
      <li parentName="ul">{`Who are they?`}</li>
      <li parentName="ul">{`From where are they?`}</li>
      <li parentName="ul">{`Which browsers do they use?`}</li>
      <li parentName="ul">{`Do they have any disabilities?`}</li>
      <li parentName="ul">{`Are they native English speakers?`}</li>
      <li parentName="ul">{`What type of devices are they using?`}</li>
      <li parentName="ul">{`How stable are their internet connections?`}</li>
      <li parentName="ul">{`What time of day do they typically use the product?`}</li>
    </ul>
    <p>{`You get the point. Most of these you probably already think about, but if you're not, now is an ideal time to start exercising those empathy muscles.`}</p>
    <h2>{`DX`}</h2>
    <p>{`Developer Experience is focusing on the developers' quality of life during the development process. A lot of DX comes down to tooling. These tools include the frameworks we choose to use, our IDE's, browser developer tools, plugins, CI/CD processes and more.`}</p>
    <p>{`If you are a team lead, it is your responsibility to set your team up for a win. Make it hard for teammates to produce poor quality by adequately setting up build processes, linting, tests and deployments.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Accessibility is sometimes a weird subject, and people get very vocal and defensive about it. It doesn't need to be that way.`}</p>
    <p>{`When you hear accessibility, most people think about blind people, but it is about a lot more. It is about real people with real lives and how we, as developers cater to these humans, our users. It is about the web being accessible to as many people as possible. No other platform can do that.`}</p>
    <p>{`According to the `}<a parentName="p" {...{
        "href": "https://www.worldbank.org/en/topic/disability"
      }}>{`World Bank Group`}</a>{`, 1 billion people, or 15% of the world's population, experiences some form of disability, with more than 100 million of them with a severe disability.`}</p>
    <p>{`To put that in perspective: IE11 currently has `}<a parentName="p" {...{
        "href": "https://caniuse.com/usage-table"
      }}>{`2.75% global usage`}</a>{`, and we're building websites that work in it. If we care about this small percentage, why would we not cater to the 15% of people with disabilities? I'm not saying that the full 15% of people with disabilities use the web, but the good news is that these users aren't using IE, that's for sure. 🤗`}</p>
    <blockquote>
      <p parentName="blockquote">{`We should not charge extra for accessibility. It is part of the quality that we, as professionals, should deliver.`}</p>
    </blockquote>
    <h3>{`Some things to consider:`}</h3>
    <ul>
      <li parentName="ul">{`Can your app be navigated with only a keyboard?`}</li>
      <li parentName="ul">{`Is your app friendly to screen readers used by people with sight impairments?`}</li>
      <li parentName="ul">{`Does your app assume users can distinguish options solely based on colour?`}</li>
      <li parentName="ul">{`Is there enough contrast between your UI elements (text vs background colours, etc.)?`}</li>
      <li parentName="ul">{`Does the user prefer reduced motion?`}</li>
      <li parentName="ul">{`Do all your videos provide subtitles?`}</li>
    </ul>
    <h3>{`What can we do?`}</h3>
    <ul>
      <li parentName="ul">{`Set up tools like ESlint that make us aware of obvious accessibility issues in our code`}</li>
      <li parentName="ul">{`Learn proper HTML semantics`}</li>
      <li parentName="ul">{`Learn about ARIA`}</li>
      <li parentName="ul">{`Use browser and CLI tools to test accessibility issues`}</li>
      <li parentName="ul">{`Play with a screen reader`}</li>
      <li parentName="ul">{`Test navigating your app using only your keyboard`}</li>
      <li parentName="ul">{`Speak up when you notice UX that could be better. Don't be a developer that only do what is given to you. Use your beautiful brain; you're a professional, remember.`}</li>
    </ul>
    <h2>{`Security`}</h2>
    <p>{`We're not all working for the CIA, but there are some simple principles we can put in place to vastly improve security in our apps.`}</p>
    <h3>{`Things to consider`}</h3>
    <ul>
      <li parentName="ul">{`Are all your pages served over HTTPS? There isn't a good reason for your app not to use it anymore. Most hosting providers provide an SSL certificate for free. More than that, many web API's and features like `}<a parentName="li" {...{
          "href": "https://developers.google.com/web/fundamentals/primers/service-workers/#you_need_https"
        }}>{`Service Workers`}</a>{` only work for sites served over HTTPS.`}</li>
      <li parentName="ul">{`Use HTTP headers like `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Strict-Transport-Security"
        }}>{`HSTS`}</a>{` and `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP"
        }}>{`CSP`}</a>{` to restrict your site from ever running over plain HTTP or loading any other resources on your page. With something as basic as this, you can almost eliminate most technical security issues.`}</li>
      <li parentName="ul">{`Is your app taking any user input? If so, make sure you sanitize everything. A good example is when writing HTML to the DOM using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`innerHTML`}</code>{`. If the HTML string you're writing is coming from a user, they can effortlessly inject a script into your app. Expect the worst. It is suitable for a developer to be slightly cynical.`}</li>
      <li parentName="ul">{`Most of the time, the most significant gap in our security plans, is us, the humans involved. A solid philosophy to follow when it comes to security is the "`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Principle_of_least_privilege"
        }}>{`principle of least privilege`}</a>{`". It simply means to provide the minimum permissions needed to accomplish a task and nothing more. For instance, if a user should only be able to add a new entry, don't give them delete permissions. At startups, when iterating quickly, this can be unnecessary red tape for your internal systems, but think about it and make a decision that suits your team the best.`}</li>
    </ul>
    <h2>{`Performance`}</h2>
    <p>{`Performance is probably one of the most critical aspects of quality software that we can look at, both from a user's and a developer's point of view. Numerous studies have reported the financial gains, or losses companies have when seconds are shaven off from their app load times. We should, therefore, `}<em>{`optimize for performance`}</em>{` as much as we can.`}</p>
    <p>{`We can also look at performance from the point of delivery: how long development of a feature takes. In other words, efficiency. Feature development is an area where faster isn't always better for quality. On the other hand, slower also does not necessarily mean better quality. Tools can help us.`}</p>
    <p>{`Let's face it; we are not good at repetitive tasks. When you're moving fast, you can not possibly remember to check everything. Set up your tools beforehand to catch apparent mistakes, and when things slip through the cracks, update your tools for the next time. I use the term "tools" very loosely here. These tools can be code linters, unit tests, spell checkers, release checklists, etc.`}</p>
    <p>{`I'm personally not someone that would argue that a Computer Science degree is necessary to be a top-class developer. One thing I would say is that everyone should understand `}<a parentName="p" {...{
        "href": "https://rob-bell.net/2009/06/a-beginners-guide-to-big-o-notation/"
      }}>{`Big-O notation`}</a>{`. I'm not talking about heavy computer science theory, but as a way to think about the code you just wrote and how well it performs. Understand the difference between time complexity versus space complexity. A function can have O(1) time complexity, but be so extremely heavy in terms of space complexity that your user's device runs out of memory. An O(1) function is useless in that scenario. With this said, Big-O is something to be aware of - if you're thinking about it, you are already winning.`}</p>
    <h2>{`Communication`}</h2>
    <p>{`I'm not going to say much about this, because we hear it all the time, communication is essential in most areas of our lives. Instead of quoting another cliché, let's talk about one or 2 places where communication is important in our day to day jobs as developers:`}</p>
    <h3>{`Code Reviews`}</h3>
    <p>{`Few communication tools make such a big difference, in the quality that we produce, as code reviews. I want to encourage you to note how you communicate on code reviews. As the person creating a pull request: are you clear about the intent of your code change? Use commit messages, PR descriptions, or before and after screenshots. These things go a long way in giving your reviewers some much-needed context.`}</p>
    <p>{`From a reviewers point of view, are you "listening" (paying attention) to the proposed code change? When you give feedback, are you merely nitpicking at a preferred code style or are you aiming to add value? A good thing to keep in mind is the concept of "`}<a parentName="p" {...{
        "href": "https://www.radicalcandor.com/"
      }}>{`Radical Candor`}</a>{`". It boils down to this: speak plainly but from a place of caring about the person and the product. It is healthy to disagree with and question a code implementation, but it is not cool doing that in a mean and condescending way. Luckily everyone at `}<a parentName="p" {...{
        "href": "https://www.goresponsive.com/"
      }}>{`Responsive`}</a>{` is generally pretty friendly, so this is rarely an issue. 🙂`}</p>
    <h3>{`Task Loggers`}</h3>
    <p>{`When you write a task description or comment, be a human, think about the person that will be reading it. If that person is not another developer, don't use all your tech jargon. If you're writing things and the other person doesn't understand you, are you communicating?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Always aim for simplicity and clarity.`}</p>
    </blockquote>
    <h2>{`Testing`}</h2>
    <p>{`I'll be first to admit that testing is not always the most fun thing to do. No one makes hacker movies about testing; it just isn't that sexy.`}</p>
    <p>{`There are probably as many opinions about the different types of testing as there are developers with right hands, so here's mine for UI testing:`}</p>
    <ul>
      <li parentName="ul">{`Not all types of testing are useful`}</li>
      <li parentName="ul">{`TDD does not work well for pure presentational components. It is much easier to add tests after the fact for this.`}</li>
      <li parentName="ul">{`Your tests should reflect how a user would interact with the UI as closely as possible - `}<a parentName="li" {...{
          "href": "https://twitter.com/kentcdodds/status/977018512689455106?lang=en"
        }}>{`tweet inspiration`}</a></li>
      <li parentName="ul">{`Sometimes tests are not worth the time investment`}</li>
      <li parentName="ul">{`Sometimes tests will save your bacon`}</li>
      <li parentName="ul">{`Unit tests are beneficial for developers and not for stakeholders - stakeholders should not be concerned with code coverage. Developers should write enough unit tests for them to feel confident, rolling out quality code.`}</li>
      <li parentName="ul">{`Unit tests work best for pure logic-based functions`}</li>
      <li parentName="ul">{`There is a much higher ROI for end-to-end and integration tests than for unit tests. It is better to spend time setting up automated tests to cover the critical journeys in your application than to chase 100% test coverage for your unit tests.`}</li>
      <li parentName="ul">{`Tests should run as part of your CI process so that adding code to your main branches requires passing tests.`}</li>
      <li parentName="ul">{`Tests should be first-class code in your app and updated alongside the rest of your app code.`}</li>
    </ul>
    <p>{`We now know that we want to put quality first, but how do we practically do that? We touched on many useful tools, but I want to take the rest of this post to chat about one particular tool that puts quality front and centre: `}<a parentName="p" {...{
        "href": "https://www.cypress.io/"
      }}>{`Cypress.io`}</a></p>
    <h2>{`Cypress`}</h2>
    <p>{`Cypress.io is an open-source, front-end testing tool, built for the modern web.`}</p>
    <h3>{`Why Cypress?`}</h3>
    <p>{`Cypress is most often compared to Selenium, but it is fundamentally different based on its architecture. Some of you, I know, have experience with tools like Chimp and Protractor that uses Selenium under the hood. Chimp uses WebDriver, which is Selenium.`}</p>
    <p>{`I'll admit that writing BDD tests on CHEP was probably my least favourite part while I was on the project. Don't get me wrong, the idea of automated UI tests excited me just as much as any other dev-nerd, but I sat most of the time debugging flakey tests. It just never seemed to do what I wanted. This led to zero confidence in the tool. What is the point in doing it if it doesn't give us confidence in the app's quality?`}</p>
    <p>{`Where Selenium and other similar tools interact with your application by sending commands from outside the browser, Cypress takes an entirely different approach and works directly within your browser.`}</p>
    <h3>{`Why is this great?`}</h3>
    <p>{`Cypress can do things that Selenium simply can't do. Things like mocking out network requests, setting items in local storage, and directly interacting with your app's state. Selenium can only make educated guesses about your app's state; Cypress knows intricately what is going on in your app.`}</p>
    <blockquote>
      <p parentName="blockquote">{`No more flake! No more pain!`}</p>
    </blockquote>
    <h3>{`Getting started`}</h3>
    <p>{`Getting up and running with Cypress is extremely easy, so I won't be covering it in this post. Install it from NPM and then to run it. Their `}<a parentName="p" {...{
        "href": "https://docs.cypress.io/guides/overview/why-cypress.html#In-a-nutshell"
      }}>{`documentation`}</a>{` is excellent - I encourage you to take a look.`}</p>
    <p>{`The first time you run the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cypress open`}</code>{` command, it will install Cypress itself and then open the interface. This UI is an Electron app, and like everything else about Cypress, it is 100% written in JavaScript.`}</p>
    <p>{`You can run Cypress with the UI Electron app or headless using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cypress run`}</code>{` command. The headless option is useful for integrating Cypress into your CI pipeline.`}</p>
    <h3>{`Killer Features`}</h3>
    <ul>
      <li parentName="ul">{`Time travel`}<ul parentName="li">
          <li parentName="ul">{`Cypress takes screenshots of every step in your tests so you can quickly go back and see how your app behaved at a certain point`}</li>
        </ul></li>
      <li parentName="ul">{`Realtime reloads`}<ul parentName="li">
          <li parentName="ul">{`Cypress is meant to run while you develop your application and will reload the particular tests as you code. Truly quality-first.`}</li>
        </ul></li>
      <li parentName="ul">{`Debuggability`}<ul parentName="li">
          <li parentName="ul">{`Since Cypress runs inside the same browser context as your app, you can easily debug it with familiar tools. Drop a `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`debugger`}</code>{` straight in the code or `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`console.log`}</code>{` to your heart's content. Should I mention that since Selenium uses the same debug protocol as the browser developer tools, it is impossible to open the dev tools while it is running? It is crazy how we have been fine with this for so long.`}</li>
          <li parentName="ul">{`On top of that, Cypress gives you meaningful error messages.`}</li>
        </ul></li>
      <li parentName="ul">{`Automatic waiting`}<ul parentName="li">
          <li parentName="ul">{`If you’ve ever written these UI end-to-end tests using a Selenium-based framework, you'll be keenly aware of the fact that you have to wait for everything. I'm not talking about waiting because Selenium is slow (that too!), I'm talking about waiting for every asynchronous action you perform. Cypress has waiting built-in, so you rarely have to think about it again.`}</li>
        </ul></li>
      <li parentName="ul">{`Spies, stubs, and clocks`}<ul parentName="li">
          <li parentName="ul">{`All the mocking functionality you're used to in regular unit tests, available for e2e tests`}</li>
        </ul></li>
      <li parentName="ul">{`Network traffic control`}<ul parentName="li">
          <li parentName="ul">{`Don't wait for an API to exist before developing your front-end. Mock out your API endpoints and start crafting your UI.`}</li>
        </ul></li>
      <li parentName="ul">{`Consistent results`}<ul parentName="li">
          <li parentName="ul">{`Because of the auto-waiting and the fact that Cypress runs inside your browser, tests are not flakey, unless your app is.`}</li>
        </ul></li>
      <li parentName="ul">{`Screenshots and videos`}<ul parentName="li">
          <li parentName="ul">{`View screenshots of failures or watch a video of your full suite run headlessly on your CI server.`}</li>
        </ul></li>
    </ul>
    <p>{`I've been relatively harsh towards Selenium up until now. It is only fair to say that Selenium is a decent product that served its time well. In the past, before the era of JavaScript frameworks, web apps were a lot simpler. Every user action almost always resulted in a page refresh, which means there was little to no state that needed to be handled by the client. Today's apps are incredibly complex, and we need new tools to help us make sense of it all. Cypress is such a tool.`}</p>
    <h3>{`Some links:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.cypress.io/img/snippets/installing-cli.486453a2.mp4"
        }}>{`Installation and Setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.cypress.io/img/snippets/writing-tests.0b201bd1.mp4"
        }}>{`Writing tests`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.cypress.io/img/snippets/running-tests.4bae660a.mp4"
        }}>{`Running tests`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.cypress.io/img/snippets/debugging.9129407a.mp4"
        }}>{`Debugging tests`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      